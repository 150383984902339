import {  IconNotifications, IconMobileNavDashboard, IconMobileNavClients, IconMobileNavConsultants, IconMobileNavSettings } from '@/components/icons'
import { useRouter } from "next/router";
import Link from "next/link";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Disclosure, Transition } from "@headlessui/react";
import {useEffect, useState} from 'react';
import { IconMagnifyingGlass } from "@/components/icons";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import WithValuesLogo from '@/components/basic/WithValuesLogo';
import Image from 'next/image';
import Searchbar from '@/components/Searchbar/Searchbar';

const TopLevelNavItemStructure = ({icon, children}) => {
    return(
        <div className="flex items-center">
            <span className="w-11">{icon}</span>
            <span className="flex-grow">{children}</span>
        </div>
    )
}

const NavItem = ({href, children, className, icon = false}) => {

    const router = useRouter();
    const css = {
        base:       "block h-11  hover:bg-primary-100 flex items-center",
        bg:         (icon) ? (router.asPath.includes(href)) ? "bg-primary-100" : "bg-picker-blue/20" : "",
        active:     href == router.asPath.includes(href) ? "bg-primary-100" : "",
        space:      icon == false ? "justify-center mt-2 rounded-lg" : "pl-4 mt-2 ",
    }
    const content = icon ?
    <>
        <TopLevelNavItemStructure icon={icon}>{children}</TopLevelNavItemStructure>
    </>

    : <>{children}</>
    return(
        <Link href={href} key={children}>
            <a className={classNames(css.base, css.active, css.space, css.bg, className)}>
                {content}
            </a>
        </Link>
    )
}

const colors = {
    "primary-dark-100": "#004664",
  };

NavItem.propTypes = {
    href:       PropTypes.string,
    children:   PropTypes.string,
    icon:       PropTypes.object,
    className:  PropTypes.string,
};

const parentNavItemOpen = "px-2 py-2 text-center rounded-bl-lg rounded-br-lg bg-picker-blue/20"

export const MobileNavigation = ({toggleNav = () => {}, open}) => {
    const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => toggleNav(false);

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, []);

    return (
    <div className={classNames("fixed z-50 w-full sm:hidden ", open ? "h-full" : "")}>
        <div className="flex items-end w-full pt-4 bg-white">
            <div className="flex justify-between w-full px-2 pb-2 space-between border-left">
            <a href="/"><span className="flex items-center w-3/4 flex-shrink"><WithValuesLogo /></span></a>
                <div className="flex items-center justify-end flex-shrink">
                    <span className="px-2 border-r border-lightwhite" onClick={(e) => {toggleNav('search')}}>
                      <IconMagnifyingGlass fill={colors["primary-dark-100"]} />
                    </span>
                    <span className="px-2 border-r border-lightwhite"><IconNotifications fill={colors["primary-dark-100"]} /></span>
                    <span className="flex items-center px-2">
                    {open ? (
                        <button className="" onClick={(e) => {toggleNav(false)}}>
                            <XIcon className="block w-6 h-6" aria-hidden="true" />
                        </button>
                    ) : (
                        <button className="" onClick={(e) => {toggleNav('menu')}}>
                            <MenuIcon className="block w-6 h-6" aria-hidden="true" />
                        </button>
                    ) }
                    </span>
                </div>

            </div>
        </div>
        {
            open ? (
                <div className="h-full overflow-auto bg-primary-dark-100">
                    <nav className='p-5 text-sm font-normal text-white bg-primary-dark-100'>

                      {open === 'search' && (
                        <Searchbar />
                      )}

                      {open === 'menu' && (
                        <>
                          <NavItem
                            href="/dashboard"
                            className="rounded-lg"
                            icon={<IconMobileNavDashboard width="25px" height="25px"/>}>
                            Dashboard
                          </NavItem>

                          <NavItem
                            href="/clients"
                            className="rounded-lg"
                            icon={<IconMobileNavClients width="25px" height="25px"/>}>
                            Clients
                          </NavItem>

                          <NavItem
                            href="/consultants"
                            className="rounded-lg"
                            icon={<IconMobileNavConsultants width="25px" height="25px"/>}>
                            Consultants
                          </NavItem>

                          <NavItem
                            href="/settings"
                            className="rounded-lg"
                            icon={<IconMobileNavSettings width="25px" height="25px"/>}>
                            Settings
                          </NavItem>
                        </>
                      )}
                    </nav>
                </div>
            ) : <></>
        }
    </div>
    )
}
