import {
  IconCalendar,
  IconCog,
  IconError,
  IconFilter,
  IconLocation,
  IconMinus,
  IconPlus,
} from "./icons";
import tailwindTheme from "../tailwind.theme";
import PropTypes from "prop-types";
import classNames from "classnames";
import AvatarCircle from "./basic/AvatarCircle";
import { useState } from "react";
import { Small } from "./basic/Text";
import { ImageDownload } from "./ImageUpload";
// Local Helpers

export const Label = ({ children }) => {
  return <>{children ? <p className="pb-2 font-bold">{children}</p> : null}</>;
};

export const InputTextUncontrolled = ({
  placeholder = "",
  errorMessage = "",
  inputref = null,
  tabIndex,
}) => {
  const [inputText, setInputText] = useState("");
  const css = {
    container: "flex flex-row items-center rounded-lg border-2 p-1",
    inputbase: "h-10 text-primary-dark-100 border-0 flex-grow",
    base: " w-full px-4 py-4 ",
    normal: "border-gray",
    error: "border-tertiary-secondary-100",
  };
  const handleChange = (event) => {
    setInputText(event.target.value);
  };
  return (
    <div
      className={classNames(
        css.container,
        errorMessage == "" ? css.normal : css.error
      )}
    >
      <input
        type="text"
        placeholder={placeholder}
        onChange={handleChange}
        ref={inputref}
        className={classNames(css.inputbase)}
        tabIndex={tabIndex || 'false'}
      />
      {errorMessage ? <IconError /> : <></>}
    </div>
  );
};

export const InputText = ({
  id = "",
  placeholder,
  errorMessage = "",
  formRegistry,
  showErrors = true,
  className = "",
  onKeyUp,
}) => {
  const css = {
    base: "h-11 w-full px-4 py-4 text-primary-dark-100 rounded-lg",
    normal: "border-gray",
    error: "border-tertiary-secondary-100",
  };

  return (
    <div>
      <input
        id={id}
        {...formRegistry}
        type="text"
        placeholder={placeholder}
        className={classNames(
          css.base,
          errorMessage == "" ? css.normal : css.error,
          className
        )}
        onKeyUp={onKeyUp}
      />
      {showErrors ? (
        <p className="mb-2 pt-1 text-sm text-tertiary-secondary-100">
          {errorMessage}
        </p>
      ) : (
        <></>
      )}
    </div>
  );
};

InputText.propTypes = {
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  formRegistry: PropTypes.object,
  showErrors: PropTypes.bool,
};

export const InputBasic = ({
  onChange = false,
  label = "",
  errorMessage,
  icon,
  placeholder,
  className = "",
}) => {
  return (
    <div>
      {label && (
        <p className="inline-block font-bold">
          {label} &nbsp;{icon}
        </p>
      )}
      <input
        placeholder={placeholder}
        onChange={onChange}
        errorMessage={errorMessage}
        className={className}
      />
    </div>
  );
};

export const Input = ({
  formRegistry,
  label = "",
  errorMessage,
  icon,
  placeholder,
  className = "",
  onKeyUp,
}) => {
  return (
    <div>
      {label && (
        <p className="inline-block pb-2 font-bold">
          {label} &nbsp;{icon}
        </p>
      )}
      <InputText
        placeholder={placeholder}
        formRegistry={formRegistry}
        errorMessage={errorMessage}
        className={className}
        onKeyUp={onKeyUp}
      />
    </div>
  );
};

export const InputFile = ({
  formRegistry,
  label = "Upload File",
  onChangeHandler,
  id = "formFile",
  sublabel = "",
}) => {
  return (
    <div className="">
      <label
        htmlFor={id}
        className="relative block flex h-11 w-72 items-center justify-center rounded-lg bg-primary-100 text-center text-sm uppercase text-white hover:cursor-pointer hover:bg-primary-50"
      >
        <span>{label}</span>
        <span className="absolute right-0 mr-3">
          <IconPlus />
        </span>
      </label>
      {sublabel && (
        <Small className="pt-2 text-center text-primary-100">{sublabel}</Small>
      )}
      <input
        {...formRegistry}
        onChange={onChangeHandler}
        className="hidden"
        type="file"
        id={id}
      />
    </div>
  );
};

// Text Areas

export const Textarea = ({
  formRegistry,
  label = "",
  description,
  errorMessage = "",
  icon,
  children,
  className = "",
}) => {
  const theme = {
    base: "h-11 w-full px-6 py-4 text-black rounded-lg ",
    normal: "border-gray",
    error: "border-tertiary-secondary-100",
    label: "inline-block pb-2 font-bold",
    errormsg: "pt-4 text-sm text-tertiary-secondary-100",
  };

  const css = classNames(
    theme.base,
    errorMessage == "" ? theme.normal : theme.error,
    className
  );

  return (
    <>
      {label ? (
        <>
          <p className={theme.label}>
            {label} &nbsp; {icon}
          </p>
        </>
      ) : null}
      <textarea {...formRegistry} className={css} rows={10}>
        {children}
      </textarea>
      {errorMessage ? <p className={theme.errormsg}>{errorMessage}</p> : null}
      {description && (
        <>
          {description}
        </>
      )}
    </>
  );
};

// Datepicker
export const InputDatepicker = ({
  label,
  formRegistry,
  errorMessage,
  icon,
}) => {
  return (
    <>
      <p className="inline-block pb-2 font-bold">{label}</p>
      <div className="flex w-full rounded-lg">
        <div className="flex items-center justify-center rounded-l-md bg-primary-100 p-2">
          <IconCalendar />
        </div>
        <input
          {...formRegistry}
          type="datetime-local"
          className="w-full rounded-r border-gray px-6 py-4 text-center text-black"
        />
        {errorMessage && (
          <p className="pt-4 text-sm text-tertiary-secondary-100">
            {errorMessage}
          </p>
        )}
      </div>
    </>
  );
};

export const InputChooseDateTime = ({ className = "", onChange = false }) => {
  return (
    <div className="flex w-full rounded-lg md:w-48">
      <div className="flex h-12 w-9 items-center justify-center rounded-l bg-primary-100 p-2">
        <IconCalendar />
      </div>
      <input
        type="datetime-local"
        className={`w-full rounded-r pl-2 text-xs ${className}`}
        onChange={onChange ? onChange : null}
      />
    </div>
  );
};

export const InputChooseDateFilter = () => {
  return (
    <div className="flex w-full rounded-lg md:w-48">
      <div className="flex h-9 w-9 items-center justify-center rounded-l bg-primary-100 p-2">
        <IconCalendar />
      </div>
      <input
        type="date"
        className="h-9 w-full rounded-r border-misc-darkblue bg-primary-dark-100 pl-2 text-xs text-white"
      />
    </div>
  );
};

export const InputReadOnlyLocation = ({ location }) => {
  return (
    <div className="flex w-full rounded-lg md:w-48">
      <div className="flex h-9 w-9 items-center justify-center rounded-l bg-primary-100 p-2">
        <IconLocation />
      </div>
      <input
        type="text"
        readOnly
        placeholder={location}
        className="h-9 w-full rounded-r border-misc-darkblue bg-primary-dark-100 pl-2 text-xs text-white placeholder:text-white"
      />
    </div>
  );
};

export const InputOpenFilters = ({ open, onToggleFilterMenu }) => {
  return (
    <div
      onClick={onToggleFilterMenu}
      className="relative flex w-full rounded-lg border border-misc-darkblue md:w-48"
    >
      <div className="flex h-9 w-9 items-center justify-center rounded-l bg-primary-100 p-2">
        <IconFilter />
      </div>
      <input
        type="text"
        readOnly
        placeholder="Filter results"
        className="h-9 w-full border-none bg-primary-dark-100 pl-2 text-xs text-white placeholder:text-white"
      />
      <button type="button">
        <div className="flex h-9 w-9 items-center justify-center border-l border-misc-darkblue p-2">
          {open ? <IconMinus /> : <IconPlus />}
        </div>
      </button>
    </div>
  );
};

export const Checkbox = ({
  id,
  label,
  checked,
  formRegistry,
  onChange,
  value = "true",
}) => {
  return (
    <>
      <input
        id={id}
        type="checkbox"
        className="mr-4 rounded-sm border-gray checked:bg-primary-100"
        checked={checked ? true : false}
        onChange={() => {
          onChange;
        }}
        {...formRegistry}
      />
      {label}
    </>
  );
};

export const CheckboxWithSettings = ({
  id,
  label,
  checked,
  formRegistry,
  formRegistry2,
  settingsClick,
}) => {
  return (
    <>
      <div className="flex h-12 w-full items-center justify-between border-b-0 border-lightwhite">
        <div>
          <input
            id={id}
            type="checkbox"
            className="xwaccent-red-800 m-3 h-4 w-4 rounded-sm checked:bg-primary-100 focus:bg-lightwhite"
            // {...(checked ? "checked" : ""}
            // onClick={onChange}
            {...formRegistry}
          />
          <span className="flex-grow pl-5">{label}</span>
        </div>
        {/* <Button clickFunction={settingsClick}>Edit</Button> */}
        <span onClick={settingsClick}>
          <IconCog fill={tailwindTheme["primary-dark"][100]} />
        </span>
      </div>
    </>
  );
};

// Select
export const Select = ({
  options = [],
  label,
  className = "w-full px-6 py-4 text-black rounded-lg border-gray",
  formRegistry,
  onChange = false,
}) => {
  return (
    <>
      {label ? <Label>{label}</Label> : <></>}
      <select
        className={classNames(className)}
        {...formRegistry}
        onChange={onChange ? onChange : null}
      >
        {options.map((item, idx) => (
          <option
            key={idx}
            value={item.value}
            // {...(v == 0 ? "disabled selected hidden" : "")}
          >
            {item.label}
          </option>
        ))}
      </select>
    </>
  );
};

export const AssignConsultantSelect = ({
  consultants,
  formRegistry,
  accessToken,
  errorMessage,
  getValues,
}) => {
  const findConsultant = (id) => {
    const find = consultants.filter((obj) => obj.id == id);
    if (find.length == 1) {
      return find[0];
    } else {
      return { avatar_id: null, given_name: null, family_name: null };
    }
  };

  const [currentConsultant, SetCurrentConsultant] = useState(
    findConsultant(getValues("consultant_id"))
  );

  // kxLog('currentConsultant', currentConsultant)

  const handleChange = (event) => {
    const consultant = SetCurrentConsultant(findConsultant(event.target.value));
  };

  const getInitials = () => {
    if (!currentConsultant.avatar_id) {
      return "N/A";
    }
    return currentConsultant?.given_name != ""
      ? `${currentConsultant?.given_name.charAt(
          0
        )}${currentConsultant?.family_name.charAt(0)}`
      : "N/A";
  };

  return (
    <div className="flex items-center">
      <div className="mr-3 flex-grow-0">
        <ImageDownload
          accessToken={accessToken}
          id={currentConsultant?.avatar_id}
          className="h-12 w-12 rounded-full object-cover"
          altPlaceholder={<AvatarCircle text={getInitials()} />}
        />
      </div>
      <div className="flex-1">
        <select
          className="h-11 w-full rounded-lg border-gray px-6 text-black"
          {...formRegistry}
          onChange={handleChange}
        >
          <option key={999} value={0}>
            No consultant
          </option>
          {consultants.map((consultant, idx) => (
            <option key={idx} value={consultant.id}>
              {consultant.family_name} {consultant.given_name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
AssignConsultantSelect.propTypes = {
  consultants: PropTypes.array,
};
