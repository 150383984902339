import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export const H1 = ({ children, className = "" }) => {
  return <h1 className={classNames("mb-5 text-4xl font-bold", className)}>{children}</h1>;
};

export const H2 = ({ children, className = "" }) => {
  return <h2 className={classNames("mb-5 text-2xl font-bold", className)}>{children}</h2>;
};

export const H3 = ({ children, className = "" }) => {
  return <h3 className={classNames("mb-5 text-xl font-bold", className)}>{children}</h3>;
};

export const H4 = ({ children, className = "" }) => {
  return <h4 className={classNames("mb-5 text-lg font-bold", className)}>{children}</h4>;
};

export const H5 = ({ children, className = "" }) => {
  return <h5 className={classNames("mb-2 text-sm font-bold", className)}>{children}</h5>;
};

export const Small = ({ children, className = "" }) => {
  return <p className={classNames("text-xs", className)}>{children}</p>;
};
