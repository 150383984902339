import { Fragment, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { SearchIcon } from "@heroicons/react/solid";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import Image from "next/image";
import WithValuesLogo from "../basic/WithValuesLogo";
import { useUser } from "@auth0/nextjs-auth0";
import Link from "next/link";
import Searchbar from "@/components/Searchbar/Searchbar";
import ApiNotification from "../Notifications";
import classNames from "classnames";

export default function AdminHeader({ api, userRole }) {
  const { user, error, isLoading } = useUser();
  const [showNotifications, setShowNotifications] = useState(false);
  const [hasUnread, setHasUnread] = useState(false);
  const [data, setData] = useState([]);
  const [deleted, setDeleted] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [loaded, setLoaded] = useState(false);

  if (userRole !== "viewer") {
    useEffect(() => {
      getNotification();
    }, [updated, deleted]);

    const getNotification = async () => {
      try {
        const notifcations = (await api.notifications.get()) || [];
        if (
          notifcations.data.filter((not) => not.status === "sent").length >= 1
        ) {
          setHasUnread(true);
        } else {
          setHasUnread(false);
        }
        setData(notifcations.data);
      } catch (err) {
        console.error(err);
      }
    };

    function classNames(...classes) {
      return classes.filter(Boolean).join(" ");
    }
  }

  const confirmNotification = async (id) => {
    try {
      const notifcations = (await api.notifications.put(id)) || [];
      setUpdated(!updated);
    } catch (err) {
      console.error(err);
    }
  };

  const deleteNotification = async (id) => {
    try {
      const notifcations = (await api.notifications.delete(id)) || [];
      setDeleted(!deleted);
    } catch (err) {
      console.error(err);
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <Disclosure
      as="nav"
      className="sticky top-0 left-0 z-20 hidden w-full bg-white sm:block"
    >
      {({ open }) => (
        <>
          <div className="flex items-center justify-between border-b border-primary-dark-100">
            <div className="flex w-64 flex-shrink-0 items-center border-r border-primary-dark-100 py-6 pl-7 pr-9">
              <a href="/">
                <WithValuesLogo />
              </a>
            </div>
            {userRole !== "viewer" && <Searchbar />}

            <div className="ml-6 hidden justify-end sm:flex sm:items-center">
              <button
                type="button"
                className="text-gray-400 hover:text-gray-500 flex-shrink-0 rounded-full bg-white p-1 focus:outline-none focus:ring-offset-2"
                onClick={() => {
                  setShowNotifications(!showNotifications);
                }}
              >
                <span className="sr-only">View notifications</span>
                {hasUnread ? (
                  <>
                    <div className="absolute z-10 h-[13px] w-[13px] animate-bounce rounded-full bg-red-600" />
                    <BellIcon className="z-10 h-6 w-6" aria-hidden="true" />
                  </>
                ) : (
                  <>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </>
                )}
              </button>
              <Menu as="div" className="relative z-50 w-16 px-4">
                <div>
                  <Menu.Button className="flex rounded-full bg-white text-sm ">
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="block h-8 w-8 rounded-full"
                      src={user.picture}
                      alt=""
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 mt-2 w-full min-w-max origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                      <p className="px-4 py-2 text-sm">{user.name}</p>
                    </Menu.Item>
                    {userRole !== "viewer" && (
                      <Menu.Item>
                        {({ active }) => (
                          <Link href="/settings" passHref>
                            <a
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "text-gray-700 block px-4 py-2 text-sm"
                              )}
                            >
                              Settings
                            </a>
                          </Link>
                        )}
                      </Menu.Item>
                    )}
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="/api/auth/logout"
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "text-gray-700 block px-4 py-2 text-sm"
                          )}
                        >
                          Sign out
                        </a>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
          {showNotifications && userRole !== "viewer" && (
            <ApiNotification
              data={data}
              onConfirm={confirmNotification}
              onDelete={deleteNotification}
            />
          )}
        </>
      )}
    </Disclosure>
  );
}
