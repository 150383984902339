import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { InputFile } from './Forms';
import ApiClient from '@/utilities/ApiClient';
import PropTypes from "prop-types";
import { kxLog } from 'app/kx';

export const ImageUpload = ({formRegistry, accessToken, onUploadSuccess, onUploadFail, label="Upload Logo", sublabel="(Logo must 180x180 jpeg / png. Max 1.0 MB)"}) => {

    const api = new ApiClient(accessToken)

    const onUpload = (event) => {
        event.preventDefault();
        async function upload(file) {

            // kxLog('ImageUploadFile', file)

            const res = await api.images.upload(file);

            if (res.statusCode == '201') {
                onUploadSuccess(res) 
            } else {
                onUploadFail(res)
            }

        }
        upload(event.target.files[0])
    }

    return(
    <>
        <InputFile
            formRegistry={formRegistry}
            label={label}
            sublabel={sublabel}
            onChangeHandler={onUpload}
        />
    </>
    )
}

export const ImageDownload = ({accessToken, id, className="", altPlaceholder=(<></>)}) => {
    const [url, setUrl] = useState()
    const api = new ApiClient(accessToken)
    const getImage = async(id) => {
        const image = await api.images.download(id);
        if (image?.data) { 
            setUrl(image.data.location) 
        } else {
            setUrl(null) 
        }
    }
    useEffect(() => {
        getImage(id)
    }, [id])
    
    return(
    <>
        {url ? <img className={classNames(className)} src={url} /> : altPlaceholder}
    </>
    )
}

ImageDownload.propTypes = {
    accessToken: PropTypes.string,
    id: PropTypes.number,
    className: PropTypes.string,
    altPlaceholder: PropTypes.object
}

ImageUpload.propTypes = {
    accessToken: PropTypes.string,
    formRegistry: PropTypes.object,
    onUploadSuccess: PropTypes.func,
    onUploadFail: PropTypes.func
}