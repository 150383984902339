export const formatNotification = (string, data) => {
    const compareString = string;
    switch (compareString) {
        case "survey_publication":
            return `${data?.survey_name ? data.survey_name : "Undefined Survey"} - Survey Published`;
        case "survey_to_review":
            return `${data?.survey_name ? data.survey_name : "Undefined Survey"} - Survey Ready to Review`;
        case "consultant_client_allocation":
            return `Consultant assigned to ${data.client_name}`;
        case "client_creation":
            return `${data?.company_name ? data.company_name : "Client"} Created`;
        case "consultant_client_update":
            return `Your client '${data.client_name}' has been updated`
        case "client_deactivation":
            return `${data?.client_name ? data.client_name : "A client"} has been deactivated`
        case "survey_upload_failure":
            return "A survey upload has failed"
        case "survey_upload_success":
            return "A survey upload has succeeded";
        case "consultant_creation":
            return "A consultant has been created";
        case "consultant_client_allocation":
            return `Allocated to ${data?.client_name ? data.client_name : "new client"}`
        case "consultant_client_deallocation":
            return `Deallocated from ${data?.client_name ? data.client_name : "client"}`
        case "admin_consultant_deactivation":
            return "An Admin consultant has been deactivated"
        default:
            return "Error displaying notification. Please contact an administrator"
    }
}