import classNames from 'classnames'
import PropTypes from 'prop-types'

const AvatarCircle = ({text, size="w-12 h-12"}) => {
  const css = {
    base: "flex items-center justify-center text-sm text-white rounded-full bg-primary-dark-100",
    width: size
  }
  return (
    <div className={classNames(css.base, css.width)}>{text}</div>
  )
}

AvatarCircle.propTypes = {
  text: PropTypes.string,
  size: PropTypes.string
}

export default AvatarCircle
