export const reportNavigation = [
  {
    name: "Report Overview",
    href: "/clients/[client_id]/surveys/[survey_id]/reports",
  },
  {
    name: "Executive Summary",
    href: "/clients/[client_id]/surveys/[survey_id]/reports/executive-summary",
  },
  {
    name: "Personal Values",
    href: "/clients/[client_id]/surveys/[survey_id]/reports/personal-values",
  },
  {
    name: "Workplace Values",
    href: "/clients/[client_id]/surveys/[survey_id]/reports/workplace-values",
  },
  {
    name: "Cultural Health",
    href: "/clients/[client_id]/surveys/[survey_id]/reports/cultural-health",
  },
  {
    name: "Belonging Score",
    href: "/clients/[client_id]/surveys/[survey_id]/reports/belonging-score",
  },
  {
    name: "Desired Values",
    href: "/clients/[client_id]/surveys/[survey_id]/reports/desired-values",
  },
  {
    name: "Cultural Distance",
    href: "/clients/[client_id]/surveys/[survey_id]/reports/cultural-distance",
  },
  {
    name: "Comparisons",
    href: "/clients/[client_id]/surveys/[survey_id]/reports/comparisons",
  },
  {
    name: "Methodology & Model",
    href: "/clients/[client_id]/surveys/[survey_id]/reports/methodology-and-model",
  },
  {
    name: "Viewers",
    href: "/clients/[client_id]/surveys/[survey_id]/reports/viewers",
  },
  {
    name: "Pending Viewers",
    href: "/clients/[client_id]/surveys/[survey_id]/reports/pending-viewers",
  },
];
