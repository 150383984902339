import React from "react";
import { Transition } from "@headlessui/react";
import { IconXClose, IconTick } from './icons';
import { formatNotification } from "@/utilities/NotificationsRenderer";

export const ApiNotification = ({ data, onDelete, onConfirm }) => {
  return (
        <Transition
          appear
          show={true}
          className="absolute right-0 transform bg-white rounded w-full max-w-md shadow-lg"
          enter="transition-all duration-150"
          enterFrom="opacity-0 scale-50"
          enterTo="opacity-100 scale-100"
          leave="transition-all duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-75"
        >
          <div
            className={`${
              true ? 'animate-enter' : 'animate-leave',
              data.length <= 0 ? 'hidden' : ''
            } w-full p-3 max-h-[30vh] border-1 border-black overflow-scroll scroll bg-white shadow-lg rounded-lg pointer-events-auto flex-col ring-1 ring-black ring-opacity-5`}
          >

          {data && data.map(item => {
            return (

              <div className={`flex flex-rw rounded-md ${item.status === 'sent' ? "bg-primary-100 opacity-60" :'bg-white'} my-4 w-full border-1 drop-shadow-md`}>
                <div className={`w-3/4 p-4 ${item.status === 'sent' ? "bg-['#80A2B1']" :'bg-white'}`}  onClick={() => {onConfirm(item.id)}}>{formatNotification(item.template, item.data)}</div>
                <button
                  onClick={() => {onDelete(item.id)}}
                  className="w-1/4 rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600"
                >
                <IconXClose />
                </button>
              </div>
            )})}
          </div>
        </Transition>
  );
};

export default ApiNotification;
