// Sometimes we need to know the hex for our theme, so we can pass it components
// This is copied directly from tailwind.config.js 
// TODO: This duplication makes me think there is a way of refactoring this.

const tailwindTheme = {
    'primary': {
      100:  '#1EB4E6',
      50:   '#8ED9F2',
      20:   '#C7ECF9'
    },
    'primary-dark': {
      100:  '#004664',
      50:   '#80A2B1',
      20:   '#BFD1D8'
    },
    'secondary': {
      100:  '#F06932',
      50:   '#F7B498',
      20:   '#FBD9CC'
    },
    'tertiary': {
      100:  '#3CAA32',
      50:   '#E38E89',
      20:   '#CEEACC'
    },
    'tertiary-secondary': {
      100:  '#C81E14',
      50:   '#E38E89',
      20:   '#F1C7C4'
    },
    'mono': {
      100:  '#000000',
      75:   '#404040',
      25:   '#BFBFBF'
    },
    'supporting': {
      100:  '#7955C3',
      50:   '#BBAAE1',
      25:   '#DDD4F0',
    },
    'supporting-secondary': {
      100:  '#FFCE21',
      50:   '#FFE791',
      25:   '#FFF3C7',
    },
    'gray': '#DADADA',
    'lightwhite': '#DEDEDE'
}

export default tailwindTheme;