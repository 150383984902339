export const isArr = (arr) => {
  return Array.isArray(arr) || false;
};

export const isObj = (obj) => {
  return Object.prototype.toString.call(obj) === "[object Object]" || false;
};

export const isStr = (str) => {
  return typeof str === "string" || false;
};

export const isFunc = (func) => {
  return func instanceof Function || false;
};

export const isDate = (date) => {
  return (date instanceof Date && !isNaN(date)) || false;
};

export const isNum = (num) => {
  return Number.isInteger(num) || false;
};

export const objHasProp = (obj, key) => {
  return Object.prototype.hasOwnProperty.call(obj, key) || false;
};

export const isHtmlElem = (elem) => {
  return elem instanceof HTMLElement;
};
