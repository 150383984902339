// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://e3fcd9fa55a440f996ce620b7c19ef69@o1091423.ingest.sentry.io/6149793',
  environment: process.env.ENV || 'production',
});
