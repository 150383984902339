import env from "@beam-australia/react-env";
import getConfig from "next/config";

import HttpClient from "./HttpClient";

const { publicRuntimeConfig } = getConfig();
class ApiClient extends HttpClient {
  constructor(accessToken) {
    super({
      baseURL:
        typeof window === "undefined"
          ? process.env.APP_API_SERVER_URL
          : env("APP_API_CLIENT_URL"),
      headers: {},
      authHeader: { Authorization: `Bearer ${accessToken}` },
      accessToken,
    });
  }

  get countries() {
    return {
      get: () => this.get("/countries"),
    };
  }

  get locales() {
    return {
      get: () => this.get("/locales"),
    };
  }

  get colours() {
    return {
      get: () => this.get("/colours"),
      view: (id) => this.get(`/colours/${id}`),
      delete: (id) => this.delete(`/colours/${id}`),
      create: (colour) => this.post("/colours", colour),
      update: (id, hex) => this.put(`/colours/${id}`, { hex }),
    };
  }

  get email() {
    return {
      get: () => this.get("/email"),
      view: (id) => this.get(`/email/${id}`),
      update: (email) => this.put(`/email`, email),
    };
  }

  get clients() {
    return {
      list: () => this.get(`/clients`),
      filteredList: (page, search, filters, perPage) =>
        this.get(
          `/clients?page=${page}&search=${search}&filter=${filters}&per_page=${perPage}`
        ),
      search: (term) => this.get(`/clients?search=${term}`),
      get: (id) => this.get(`/clients/${id}`),
      delete: (id) => this.delete(`/clients/${id}`),
      create: (client) => this.post("/clients", client),
      update: (client) => this.put(`/clients/${client.id}`, client),
      //   surveys
      listSurveys: (id, page, perPage) =>
        this.get(`/clients/${id}/surveys?page=${page}&per_page=${perPage}`),
      viewSurvey: (id, survey_id) =>
        this.get(`/clients/${id}/surveys/${survey_id}`), // how to pass in query params, sort etc?? probably as an object of params
      createSurvey: (id) => this.post(`/clients/${id}/surveys`),
      updateSurvey: (id, survey) =>
        this.put(`/clients/${id}/surveys/${survey.id}`, survey),
      deleteSurvey: (id, survey) =>
        this.delete(`/clients/${id}/surveys/${survey}`),
      // Email notifications
      emailReminderTypesList: (client_id, survey_id) =>
        this.get(`/clients/${client_id}/surveys/${survey_id}/reminder-types`),
      emailRemindersList: (client_id, survey_id) =>
        this.get(`/clients/${client_id}/surveys/${survey_id}/reminders`),

      emailRemindersCreate: (client_id, survey_id, reminder) => {
        reminder.offset = new Date().getTimezoneOffset();
        this.post(
          `/clients/${client_id}/surveys/${survey_id}/reminders`,
          reminder
        );
      },

      emailRemindersDelete: (client_id, survey_id, email_reminder_id) =>
        this.delete(
          `/clients/${client_id}/surveys/${survey_id}/reminders/${email_reminder_id}`
        ),
      emailRemindersCSVUpload: (client_id, survey_id, file, fields, grouping) =>
        this.postFile(
          `/clients/${client_id}/surveys/${survey_id}/recipients`,
          file,
          fields,
          grouping
        ),
      emailSendTest: (client_id, survey_id, recipientEmailsArray) =>
        this.post(`/clients/${client_id}/surveys/${survey_id}/deliverability`, {
          recipients: recipientEmailsArray,
        }),
      // Structure
      structuresList: (client_id) =>
        this.get(`/clients/${client_id}/structures`),
      structuresGet: (client_id, type) =>
        this.get(`/clients/${client_id}/structures/${type}`),
      structuresUpdate: (client_id, structure_type, structure) =>
        this.put(
          `/clients/${client_id}/structures/${structure_type}`,
          structure
        ),
      // Structure Options
      structuresDelete: (client_id, structure) =>
        this.delete(
          `/clients/${client_id}/structures/${structure.type}/options/${structure.id}`
        ),
      structuresCreate: (client_id, structure) => {
        this.post(
          `/clients/${client_id}/structures/${structure.type}/options`,
          structure
        );
      },
      structuresOptionsCreate: (client_id, type, data) => {
        this.post(`/clients/${client_id}/structures/${type}/options`, data);
      },
      structuresOptionsUpdate: (client_id, type, id, data) => {
        this.put(
          `/clients/${client_id}/structures/${type}/options/${id}`,
          data
        );
      },
      structuresOptionsDelete: (client_id, structure_type, option_id) =>
        this.delete(
          `/clients/${client_id}/structures/${structure_type}/options/${option_id}`
        ),
      structureOptionsGet: (client_id, structure_type, option_id) =>
        this.get(
          `/clients/${client_id}/structures/${structure_type}/options/${option_id}`
        ),
      uploadDataSecurity: (client_id, file) =>
        this.postFile(`/clients/${client_id}/upload-data-security-file`, file),
      downloadDataSecurity: (client_id) =>
        this.get(`/clients/${client_id}/download-data-security-file/`),
      exportSurvey: (id) => this.getText(`/clients/export-survey/${id}`),
      importSurvey: (data) => this.post('/clients/import-survey', data)
    };
  }

  get notifications() {
    return {
      get: () => this.get(`/notifications`),
      delete: (id) => this.delete(`/notifications/${id}`),
      put: (id) => this.put(`/notifications/${id}`, id),
    };
  }

  get consultants() {
    return {
      list: () => this.get("/consultants"),
      filteredList: (page, search, filters, perPage) =>
        this.get(
          `/consultants?page=${page}&search=${search}&filter=${filters}&per_page=${perPage}`
        ),
      get: (id) => this.get(`/consultants/${id}`),
      delete: (id) => this.delete(`/consultants/${id}`),
      create: (consultant) => this.post("/consultants", consultant),
      update: (consultant) =>
        this.put(`/consultants/${consultant.id}`, consultant),
      clients: (id) => this.get(`/clients?consultant=${id}`),
    };
  }

  get images() {
    return {
      upload: (file) => this.postFile("/images", file),
      download: (id) => this.get(`/images/${id}`),
      delete: (id) => this.delete(`/images/${id}`),
    };
  }

  get insights() {
    return {
      get: (survey, location) =>
        this.get(`/surveys/${survey}/insights/${location}`),
      create: (survey, location, data) =>
        this.post(`/surveys/${survey}/insights/${location}`, data),
      update: (survey, location, data) =>
        this.put(`/surveys/${survey}/insights/${location}`, data),
    };
  }

  get viewers() {
    return {
      list: (survey, page = 1, perPage = 10) =>
        this.get(`/surveys/${survey}/viewers?page=${page}&per_page=${perPage}`),
      delete: (survey, viewerId) =>
        this.delete(`/surveys/${survey}/viewers/${viewerId}`),
      get: (survey, viewerId) =>
        this.get(`/surveys/${survey}/viewers/${viewerId}`),
      update: (survey, viewerId, status) =>
        this.put(`/surveys/${survey}/viewers/${viewerId}`, status),
    };
  }

  get viewerReports() {
    return {
      list: (viewer, page = 1, perPage = 10) =>
        this.get(`/surveys/${viewer}/reports?page=${page}&per_page=${perPage}`),
    };
  }

  get pendingViewers() {
    return {
      list: (survey, page = 1, perPage = 10) =>
        this.get(
          `/surveys/${survey}/viewers-pending?page=${page}&per_page=${perPage}`
        ),
      delete: (survey, viewerId) =>
        this.delete(`/surveys/${survey}/viewers-pending/${viewerId}`),
      get: (survey, viewerId) =>
        this.get(`/surveys/${survey}/viewers-pending/${viewerId}`),
      update: (survey, viewerId, attributes) =>
        this.put(`/surveys/${survey}/viewers-pending/${viewerId}`, attributes),
      create: (survey, attributes) =>
        this.post(`/surveys/${survey}/viewers-pending/`, attributes),
    };
  }

  // /reports/overview/email-delivery",
  // /reports/overview/survey-statuses",
  // /reports/overview/survey-completion-time",
  // /reports/overview/survey-progress",
  // /reports/overview/survey-demographics/{type}",
  // /reports/overview/device-types",
  // /reports/personal-values/intrinsic-vs-extrinsic",
  // /reports/personal-values/categories",
  // /reports/personal-values/words",
  // /reports/workplace-values/intrinsic-v-extrinsic",
  // /reports/workplace-values/categories",
  // /reports/workplace-values/words",
  // /reports/desired-values/intrinsic-v-extrinsic",
  // /reports/desired-values/categories",
  // /reports/desired-values/words"
  get reports() {
    return {
      list: () => this.get("/reports"),
      listEmailTemplates: () => this.get("/reports/email-templates"),
      getEmailDelivery: (template, survey_id = false) => {
        return this.get(
          "/reports/overview/email-delivery?template=survey_invitation"
        );
      },
      getOverviewReport: (type, survey_id = false) => {
        const params = {};

        if (survey_id) {
          params.survey_id = survey_id;
        }

        return this.get(
          `/reports/overview/${type}?${new URLSearchParams(params)}`
        );
      },
      getReport: (type, filters = {}, client_id = false, survey_id = false) => {
        if (client_id) {
          filters.client = client_id;
        }

        if (survey_id) {
          filters.survey = survey_id;
        }

        return this.get(`/reports/${type}?${new URLSearchParams(filters)}`);
      },
    };
  }

  get demographics() {
    return {
      list: () => this.get("/default-demographics"),
      get: (type) => this.get(`/default-demographics/${type}`),
      delete: (id) => this.delete(`/default-demographics/${id}`),
      create: (demographic) => this.post("/default-demographics", demographic),
      update: (demographic) =>
        this.put(`/default-demographics/${demographic.id}`, demographic),
      createOption: (type, value) =>
        this.post(`/default-demographics/${type}/options`, value),
      updateOption: (type, id, value) =>
        this.put(`/default-demographics/${type}/options/${id}`, value),
      deleteOption: (type, id) =>
        this.delete(`/default-demographics/${type}/options/${id}`),
    };
  }

  get pins() {
    return {
      validate: (survey, pin, name, language) => {
        return this.post(`/pins/${survey}`, { pin, name, language });
      },
    };
  }

  get surveys() {
    const root = "/surveys";
    return {
      list: () => this.get(root),
      createResponse: (survey, respondent) => {
        if (respondent) {
          return this.post(`${root}/${survey}?respondent=${respondent}`);
        }

        return this.post(`${root}/${survey}`);
      },
      updateResponse: (survey, respondent, response) =>
        this.put(`${root}/${survey}/${respondent}`, response),
    };
  }

  get images() {
    const root = "/images";
    return {
      upload: (file) => this.postFile(`${root}`, file),
      download: (type) => this.get(`${root}/${type}`),
      delete: (id) => this.delete(`/default-demographics/${id}`),
    };
  }

  get words() {
    const root = "/words";
    return {
      get: (reportType, surveyType = "standard", language) =>
        this.get(`${root}/${reportType}/${surveyType}?language=${language}`),
      getConstants: (language) =>
        this.get(`${root}/constants?language=${language}`),
      lookup: () => this.get(`${root}/lookup`)
    };
  }
  // emailRemindersCSVUpload: (client_id, survey_id, file) =>
  // this.postFile(
  //   `/clients/${client_id}/surveys/${survey_id}/recipients`,
  //   file
  // ),
  // TODO make a generic func for reports taht take no args and specific for those that have them
  //   "/reports/overview/email-delivery",
  //   "/reports/overview/survey-statuses",
  //   "/reports/overview/survey-completion-time",
  //   "/reports/overview/survey-progress",
  //   "/reports/overview/survey-demographics/{type}",
  //   "/reports/overview/device-types",
  //   "/reports/personal-values/intrinsic-vs-extrinsic",
  //   "/reports/personal-values/categories",
  //   "/reports/personal-values/words",
  //   "/reports/workplace-values/intrinsic-v-extrinsic",
  //   "/reports/workplace-values/categories",
  //   "/reports/workplace-values/words",
  //   "/reports/desired-values/intrinsic-v-extrinsic",
  //   "/reports/desired-values/categories",
  //   "/reports/desired-values/words"

  //   ### List Surveys [GET /clients/{id}/surveys{?per_page,page,sort_order[asc,desc],sort_by[name,date,completion,time,expiry,status]}]
  // + Parameters
  //     + id: `9af6b5a7-81cc-476c-a704-70729c364aa1` (string) - the client ID.

  // ### View Survey [GET /client/{id}/surveys/{survey}]
  // + Parameters
  //     + id: `9af6b5a7-81cc-476c-a704-70729c364aa1` (string) - the client ID.
  //     + survey: `2166f6e1-f5ce-4355-a1a4-7fd33e8890e3` (string) - the survey UUID.

  // ### Create Survey [POST /clients/{id}/surveys]
  // + Parameters
  //     + id: `9af6b5a7-81cc-476c-a704-70729c364aa1` (string) - the client ID.

  // ### Update Survey [PUT /clients/{id}/surveys/{survey}]
  // + Parameters
  //     + id: `9af6b5a7-81cc-476c-a704-70729c364aa1` (string) - the client ID.
  //     + survey: `2166f6e1-f5ce-4355-a1a4-7fd33e8890e3` (string) - the survey UUID.

  // ### Delete Survey [DELETE /clients/{id}/surveys/{survey}]
  // + Parameters
  //     + id: `9af6b5a7-81cc-476c-a704-70729c364aa1` (string) - the client ID.
  //     + survey: `2166f6e1-f5ce-4355-a1a4-7fd33e8890e3` (string) - the survey UUID.

  /*
FORMAT: 1A
HOST: https://api.brands.withvalues.com/
## Countries [/countries]
### List Countries [GET /countries]

## Locales [/locales]
### List Locales [GET /locales]

## Colours [/colours]
### List Colours [GET /colours]



### View Colour [GET /colours/{id}]
+ Parameters
    + id: `c01` (string) - the colour ID.

### Update Colour [PUT /colours/{id}]
+ Parameters
    + id: `c01` (string) - the colour ID.
+ Request (application/json)

        {
            "hex": "#FF0202",
        }

## Email [/email]
### View Email [GET /email]
### Update Email [PUT /email]
+ Request (application/json)

        {
            "email": "noreply@brandswithvalues.com"
        }

### List Email Prefixes On Block List [GET /email/prefix-block-list]

## Default Demographics [/default-demographics]
### List Default Demographic Types [GET /default-demographics]
### View Default Demographic Type [GET /default-demographics/{type}]
+ Parameters
    + type: `gender` (string) - the demographic type.

### View Default Demographic Option [GET /default-demographics/{type}/options/{id}]
+ Parameters
    + type: `gender` (string) - the demographic type.
    + id: `1` (integer) - the demographic option ID.

### Update Default Demographic [PUT /default-demographics/{type}]
+ Parameters
    + type: `gender` (string) - the demographic type.

### Create Default Demographic Option [POST /default-demographics/{type}/options]
+ Parameters
    + type: `gender` (string) - the demographic type.

### Delete Default Demographic Option [DELETE /default-demographics/{type}/options/{id}]
+ Parameters
    + type: `gender` (string) - the demographic type.
    + id: `1` (integer) - the demographic option ID.



### List Personal Values [GET /words/personal-values/{surveyType}]
+ Parameters
    + surveyType: `standard` (string) - the survey type.

### List Workplace Values [GET /words/workplace-values/{surveyType}]
+ Parameters
    + surveyType: `standard` (string) - the survey type.

### List Desired Values [GET /words/desired-values/{surveyType}]
+ Parameters
    + surveyType: `standard` (string) - the survey type.

## Clients [/clients]
### List Clients [GET /clients{?per_page,page,search}]
### View Client [GET /client/{id}]
### Create Client [POST /clients]
+ Request (application/json)

        {
            "name": "Soak Digital",
            "locale": "en-GB",
            "logo_id": 1004,
            "address_line_1": "2 Millennium Plain",
            "address_line_2": "Norwich",
            "country": "United Kingdom",
            "postcode": "NR2 1TF",
            "contact_given_name": "John",
            "contact_family_name": "Smith",
            "contact_job_title": "Founder",
            "contact_telephone": "01603 630 631",
            "contact_email": "john.smith@domain.com",
            "contact_website": "https://soak.co.uk",
            "consultant_id": 1007
        }

### Update Client [PUT /clients/{id}]
+ Parameters
    + id: `9af6b5a7-81cc-476c-a704-70729c364aa1` (string) - the client ID.

+ Request (application/json)

        {
            "name": "Soak Digital",
            "locale": "en-GB",
            "logo_id": 1004,
            "address_line_1": "2 Millennium Plain",
            "address_line_2": "Norwich",
            "country": "United Kingdom",
            "postcode": "NR2 1TF",
            "contact_given_name": "John",
            "contact_family_name": "Smith",
            "contact_job_title": "Founder",
            "contact_telephone": "01603 630 631",
            "contact_email": "john.smith@domain.com",
            "contact_website": "https://soak.co.uk",
            "consultant_id": 1007
        }

### Deactivate Client [DELETE /clients/{id}]
+ Parameters
    + id: `9af6b5a7-81cc-476c-a704-70729c364aa1` (string) - the client ID.

### Upload Data Security File [POST /clients/{id}/upload-data-security-file]
+ Parameters

    + id: `9af6b5a7-81cc-476c-a704-70729c364aa1` (string) - the client ID.

### Download Data Security File [GET /clients/{id}/download-data-security-file/{token}]
+ Parameters
    + id: `9af6b5a7-81cc-476c-a704-70729c364aa1` (string) - the client ID.
    + token: U2FsdGVkX19NlKfhfbyhDsbOQPh18/kP6ufEx2JoJv5uA= (string) - the ephemeral token

### List Surveys [GET /clients/{id}/surveys{?per_page,page,sort_order[asc,desc],sort_by[name,date,completion,time,expiry,status]}]
+ Parameters
    + id: `9af6b5a7-81cc-476c-a704-70729c364aa1` (string) - the client ID.

### View Survey [GET /client/{id}/surveys/{survey}]
+ Parameters
    + id: `9af6b5a7-81cc-476c-a704-70729c364aa1` (string) - the client ID.
    + survey: `2166f6e1-f5ce-4355-a1a4-7fd33e8890e3` (string) - the survey UUID.


### Create Survey [POST /clients/{id}/surveys]
+ Parameters
    + id: `9af6b5a7-81cc-476c-a704-70729c364aa1` (string) - the client ID.

### Update Survey [PUT /clients/{id}/surveys/{survey}]
+ Parameters
    + id: `9af6b5a7-81cc-476c-a704-70729c364aa1` (string) - the client ID.
    + survey: `2166f6e1-f5ce-4355-a1a4-7fd33e8890e3` (string) - the survey UUID.

### Delete Survey [DELETE /clients/{id}/surveys/{survey}]
+ Parameters
    + id: `9af6b5a7-81cc-476c-a704-70729c364aa1` (string) - the client ID.
    + survey: `2166f6e1-f5ce-4355-a1a4-7fd33e8890e3` (string) - the survey UUID.

### Survey Test Email Deliverability [PUT /clients/{id}/surveys/{survey}/deliverability]
+ Parameters
    + id: `9af6b5a7-81cc-476c-a704-70729c364aa1` (string) - the client ID.
    + survey: `2166f6e1-f5ce-4355-a1a4-7fd33e8890e3` (string) - the survey UUID.

### List Email Reminder Types [GET /clients/{id}/surveys/{survey}/reminder-types]
+ Parameters
    + id: `9af6b5a7-81cc-476c-a704-70729c364aa1` (string) - the client ID.
    + survey: `2166f6e1-f5ce-4355-a1a4-7fd33e8890e3` (string) - the survey UUID.

### List Email Reminders [GET /clients/{id}/surveys/{survey}/reminders]
+ Parameters
    + id: `9af6b5a7-81cc-476c-a704-70729c364aa1` (string) - the client ID.
    + survey: `2166f6e1-f5ce-4355-a1a4-7fd33e8890e3` (string) - the survey UUID.

    ### View Email Reminder [GET /client/{id}/surveys/{survey}/reminders/{reminder}]
+ Parameters
    + id: `9af6b5a7-81cc-476c-a704-70729c364aa1` (string) - the client ID.
    + survey: `2166f6e1-f5ce-4355-a1a4-7fd33e8890e3` (string) - the survey UUID.
    + reminder: `1002` (integer) - the email reminder ID.

### Create Email Reminder [POST /clients/{id}/surveys/{survey}/reminders]
+ Parameters
    + id: `9af6b5a7-81cc-476c-a704-70729c364aa1` (string) - the client ID.
    + survey: `2166f6e1-f5ce-4355-a1a4-7fd33e8890e3` (string) - the survey UUID.

### Delete Email Reminder [DELETE /clients/{id}/surveys/{survey}/reminders/{reminder}]
+ Parameters
    + id: `9af6b5a7-81cc-476c-a704-70729c364aa1` (string) - the client ID.
    + survey: `2166f6e1-f5ce-4355-a1a4-7fd33e8890e3` (string) - the survey UUID.
    + reminder: `1002` (integer) - the email reminder ID.


### Upload Survey Recipients [POST /clients/{id}/surveys/{survey}/recipients]
+ Parameters
    + id: `9af6b5a7-81cc-476c-a704-70729c364aa1` (string) - the client ID.
    + survey: `2166f6e1-f5ce-4355-a1a4-7fd33e8890e3` (string) - the survey UUID.

### List Client Structures [GET /clients/{id}/structures]
+ Parameters
    + id: `9af6b5a7-81cc-476c-a704-70729c364aa1` (string) - the client ID.

### View Client Structure [GET /clients/{id}/structures/{type}]
+ Parameters
    + id: `9af6b5a7-81cc-476c-a704-70729c364aa1` (string) - the client ID.
    + type: `location` (string) - the structure type.

### Update Client Structure [PUT /clients/{id}/structures/{type}]
+ Parameters
    + id: `9af6b5a7-81cc-476c-a704-70729c364aa1` (string) - the client ID.
    + type: `company` (string) - the structure type.

### View Client Structure Option [GET /clients/{id}/structures/{type}/options/{option}]
+ Parameters
    + id: `9af6b5a7-81cc-476c-a704-70729c364aa1` (string) - the client ID.
    + type: `location` (string) - the structure type.
    + option: `1006` (string) - the option ID.

### Create Client Structure Option [POST /clients/{id}/structures/{type}/options]
+ Parameters
    + id: `9af6b5a7-81cc-476c-a704-70729c364aa1` (string) - the client ID.
    + type: `location` (string) - the structure type.

### Update Client Structure Option [PUT /clients/{id}/structures/{type}/options/{option}]
+ Parameters
    + id: `9af6b5a7-81cc-476c-a704-70729c364aa1` (string) - the client ID.
    + type: `location` (string) - the structure type.
    + option: `1006` (string) - the option ID.

### Delete Client Structure Option [DELETE /clients/{id}/structures/{type}/options/{option}]
+ Parameters
    + id: `9af6b5a7-81cc-476c-a704-70729c364aa1` (string) - the client ID.
    + type: `location` (string) - the structure type.
    + option: `1006` (string) - the option ID.

## Consultants [/consultants]
### List Consultants [GET /consultants{?per_page,page}]
### View Consultant [GET /consultants/{id}]
+ Parameters
    + id: `1007` (integer) - the client ID.

### Create Consultant [POST /consultants]
+ Request (application/json)

        {
            "locale": "en-GB",
            "given_name": "John",
            "family_name": "Smith",
            "email": "john.smith@domain.com",
            "avatar_id": 1004
        }

### Update Consultant [PUT /consultants/{id}]
+ Parameters
    + id: `1007` (integer) - the client ID.
+ Request (application/json)

        {
            "locale": "en-GB",
            "given_name": "John",
            "family_name": "Smith",
            "email": "john.smith@domain.com",
            "avatar_id": 1004
        }

### Deactivate Consultant [DELETE /consultants/{id}]
+ Parameters
    + id: `1007` (integer) - the consultant ID.

## Notifications [/notifications]
### List Notifications [GET /notifications]
### View Notification [GET /notifications/{id}]
+ Parameters
    + id: `1017` (integer) - the notification ID.


### Acknowledge Notification [PUT /notifications/{id}]
+ Parameters
    + id: `1017` (integer) - the notification ID.

### Dismiss Notification [DELETE /notifications/{id}]
+ Parameters
    + id: `1017` (integer) - the notification ID.

## Images [/images]
### Upload [POST /images]
+ Request (multipart/form-data; boundary=---BOUNDARY)

        -----MultipartBoundary
        Content-Disposition: form-data; name="image[file]"; filename="image.png"
        Content-Type: image/png
        Content-Transfer-Encoding: base64

        /9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh0a
        HBwgJC4nICIsIxwcKDcpLDAxNDQ0Hyc5PTgyPC4zNDL/2wBDAQkJCQwLDBgNDRgyIRwhMjIyMjIy
        MjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjL/wAARCAABAAEDASIA
        AhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAf/xAAUEAEAAAAAAAAAAAAAAAAAAAAA/8QAFAEB
        AAAAAAAAAAAAAAAAAAAAAP/EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/AL+AD//Z

        --MultipartBoundry--


### Download [GET /images/{id}]
+ Parameters
    + id: `1004` (integer) - the image ID.

### Delete [DELETE /images/{id}]
+ Parameters
    + id: `1004` (integer) - the image ID.

## Surveys [/surveys]
### List Surveys [GET /surveys{?per_page,page,sort_order[asc,desc],sort_by[name,date,completion,time,expiry,status]}]

### Create Survey Response [POST /surveys/{survey}/{?respondent}]
+ Parameters
    + survey: `2166f6e1-f5ce-4355-a1a4-7fd33e8890e3` (string) - the survey UUID.
    + respondent: `88565439-3059-4895-a2be-e9f393cbe551` (string) - the respondent UUID (optional).

### Update Survey Response [PUT /surveys/{survey}/{respondent}]
+ Parameters
    + survey: `2166f6e1-f5ce-4355-a1a4-7fd33e8890e3` (string) - the survey UUID.
    + respondent: `88565439-3059-4895-a2be-e9f393cbe551` (string) - the respondent UUID.

+ Request (application/json)

        {
            "demographics": {
                "gender": 11, // Optional
                "ethnicity": 10, // Optional
                "age": 8, // Optional
                "disability": 9, // Optional
                "sexual_orientation": 12, // Optional
                "tenure": 13 // Optional
            },
            "structures": {
                "company": 14, // Optional
                "location": 15, // Optional
                "unit": 16, // Optional
                "department": 17, // Optional
                "seniority": 18, // Optional
                "other": 19 // Optional
            },
            "personal_values": [ // Optional
                1,
                5,
                17,
                21,
                23,
                36,
                49,
                57,
                58,
                80
            ],
            "workplace_values": [ // Optional
                6,
                11,
                18,
                30,
                45,
                71,
                73,
                81,
                90,
                95
            ],
            "desired_values": [ // Optional
                12,
                14,
                25,
                39,
                41,
                48,
                56,
                68,
                69,
                84
            ],
            "name": "John Smith", // Optional
            "company": "Soak Digital", // Optional
            "job_title": "Founder", // Optional
            "email": "john.smith@domain.com" // Optional
            "complete": true, // Optional (only send when all other values have been supplied)
        }


### List Survey Insight Locations [GET /surveys/insight-locations]

### View Survey Insight [GET /surveys/{survey}/insights/{location}]
+ Parameters
    + survey: `2166f6e1-f5ce-4355-a1a4-7fd33e8890e3` (string) - the survey UUID.
    + location: `pv_head` (string) - the location of the insight.

### Create Survey Insight [POST /surveys/{survey}/insights/{location}]
+ Parameters
    + survey: `2166f6e1-f5ce-4355-a1a4-7fd33e8890e3` (string) - the survey UUID.
    + location: `pv_head` (string) - the location of the insight.

### Update Survey Insight [PUT /surveys/{survey}/insights/{location}]
+ Parameters
    + survey: `2166f6e1-f5ce-4355-a1a4-7fd33e8890e3` (string) - the survey UUID.
    + location: `pv_head` (string) - the location of the insight.

### Delete Survey Insight [DELETE /surveys/{survey}/insights/{location}]
+ Parameters
    + survey: `2166f6e1-f5ce-4355-a1a4-7fd33e8890e3` (string) - the survey UUID.
    + location: `pv_head` (string) - the location of the insight.

## Reports [/reports]
### List Reports [GET /reports]
+ Response 200 (application/json)

        {
            "data": [
                "/reports/overview/email-delivery",
                "/reports/overview/survey-statuses",
                "/reports/overview/survey-completion-time",
                "/reports/overview/survey-progress",
                "/reports/overview/survey-demographics/{type}",
                "/reports/overview/device-types",
                "/reports/personal-values/intrinsic-vs-extrinsic",
                "/reports/personal-values/categories",
                "/reports/personal-values/words",
                "/reports/workplace-values/intrinsic-v-extrinsic",
                "/reports/workplace-values/categories",
                "/reports/workplace-values/words",
                "/reports/desired-values/intrinsic-v-extrinsic",
                "/reports/desired-values/categories",
                "/reports/desired-values/words"
            ]
        }

### List Email Templates [GET /reports/email-templates]
### View Overview Email Delivery Report [GET /reports/overview/email-delivery{?template[reminder,survey_invitation],client,survey}]
### View Overview Survey Statuses Report [GET /reports/overview/survey-statuses{?client,survey}]
### View Overview Device Types Report [GET /reports/overview/device-types{?client,survey}]
### View Overview Survey Completion Time Report [GET /reports/overview/survey-completion-time{?client,survey}]
### View Overview Survey Progress Report [GET /reports/overview/survey-progress{?client,survey}]
### View Overview Survey Demographic Report [GET /reports/overview/survey-demographics/{type}{?client,survey,gender[],ethnicity[],age[],disability[],sexual_orientation[],tenure[],company[],unit[],location[],department[],seniority[],other[]}]
### View Personal Values Areas Report [GET /reports/personal-values/areas{?client,survey,gender[],ethnicity[],age[],disability[],sexual_orientation[],tenure[],company[],unit[],location[],department[],seniority[],other[]}]
### View Personal Values Motivation Report [GET /reports/personal-values/motivation{?client,survey,gender[],ethnicity[],age[],disability[],sexual_orientation[],tenure[],company[],unit[],location[],department[],seniority[],other[]}]
### View Personal Values Words Report [GET /reports/personal-values/words{?client,survey,type[demographic,structure],filter[gender,ethnicity,age,disability,sexual_orientation,tenure,company,unit,location,department,seniority,other],gender[],ethnicity[],age[],disability[],sexual_orientation[],tenure[],company[],unit[],location[],department[],seniority[],other[]}]
### View Workplace Values Areas Report [GET /reports/workplace-values/areas{?client,survey,gender[],ethnicity[],age[],disability[],sexual_orientation[],tenure[],company[],unit[],location[],department[],seniority[],other[]}]
### View Workplace Values Motivation Report [GET /reports/workplace-values/motivation{?client,survey,gender[],ethnicity[],age[],disability[],sexual_orientation[],tenure[],company[],unit[],location[],department[],seniority[],other[]}]
### View Workplace Values Words Report [GET /reports/workplace-values/words{?client,survey,type[demographic,structure],filter[gender,ethnicity,age,disability,sexual_orientation,tenure,company,unit,location,department,seniority,other],gender[],ethnicity[],age[],disability[],sexual_orientation[],tenure[],company[],unit[],location[],department[],seniority[],other[]}]
### View Desired Values Areas Report [GET /reports/desired-values/areas{?client,survey,gender[],ethnicity[],age[],disability[],sexual_orientation[],tenure[],company[],unit[],location[],department[],seniority[],other[]}]
### View Desired Values Motivation Report [GET /reports/desired-values/motivation{?client,survey,gender[],ethnicity[],age[],disability[],sexual_orientation[],tenure[],company[],unit[],location[],department[],seniority[],other[]}]
### View Desired Values Words Report [GET /reports/desired-values/words{?client,survey,type[demographic,structure],filter[gender,ethnicity,age,disability,sexual_orientation,tenure,company,unit,location,department,seniority,other],gender[],ethnicity[],age[],disability[],sexual_orientation[],tenure[],company[],unit[],location[],department[],seniority[],other[]}]
*/
}

export default ApiClient;
